import {
  AdditionalLicenseType,
  ClasspadNetPlusAppLicense,
  ClasspadNetPlusManagerLicense,
} from "./AdditionalLicenseType";
import { Product } from "./Product";

export interface PlainAdditionalLicense {
  id: string;
  license: any;
  type: string;
  durationInDays?: number;
  isActivated: boolean;
  validUntil: string;
  userId: string;
  createdAt: string;
  updatedAt: string;
}
export interface AdditionalLicense {
  id: string;
  license: ClasspadNetPlusAppLicense | ClasspadNetPlusManagerLicense;
  type: AdditionalLicenseType;
  durationInDays?: number;
  isActivated: boolean;
  validUntil: Date;
  userId: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface PlainLicense {
  product: string;
  validUntil: string;
  additionalLicenses?: PlainAdditionalLicense[];
}

export class License {
  constructor(
    public readonly product: Product,
    public readonly validUntil: Date,
    public readonly additionalLicenses?: AdditionalLicense[]
  ) {}
}

export class LicenseTrial {
  constructor(
    public readonly product: Product,
    public readonly validUntil: string
  ) {}
}

export class LicenseCode {
  constructor(
    public readonly id: string,
    public readonly code: string,
    public readonly isActivated: boolean | null,
    public readonly durationInDays: number,
    public readonly products: Product[],
    public readonly createdAt: Date,
    public readonly updatedAt: Date,
    public readonly activatedAt: Date,
    public readonly userId: string | undefined,
    public readonly isPromotional: boolean,
    public readonly expiresAt: Date | null,
    public readonly startDate?: Date,
    public readonly endDate?: Date
  ) {}
}

export function licenseFromPlain({
  product,
  validUntil,
  additionalLicenses,
}: PlainLicense): License {
  return new License(
    Product[product.toUpperCase() as keyof typeof Product],
    new Date(validUntil),
    additionalLicenses
      ? additionalLicenses.map((l) => additionalLicenseFromPlain(l))
      : undefined
  );
}

export function additionalLicenseFromPlain({
  id,
  license,
  type,
  durationInDays,
  isActivated,
  validUntil,
  userId,
  createdAt,
  updatedAt,
}: PlainAdditionalLicense): AdditionalLicense {
  return {
    id,
    license,
    type: AdditionalLicenseType[
      type.toUpperCase() as keyof typeof AdditionalLicenseType
    ],
    durationInDays,
    isActivated,
    validUntil: new Date(validUntil),
    userId,
    createdAt: new Date(createdAt),
    updatedAt: new Date(updatedAt),
  };
}
