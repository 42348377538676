import { useState } from "react";
import RestrictedTo from "../RestrictedTo/RestrictedTo";
import { PageHeader } from "../PageHeader/PageHeader";
import { Formik, Form } from "formik";

import styles from "./History.module.scss";
import { Grid, GridItem } from "../Grid/Grid";
import { Role } from "../../types/Role";
import { TextInput } from "../TextInput/TextInput";
import { DatepickerField } from "../DatepickerField/DatepickerField";
import { LabeledCheckbox } from "../LabeledCheckbox/LabeledCheckbox";
import { Button } from "../Button/Button";
import { Column, SortColumn } from "react-data-grid";
import Pagination from "../Paginate/Pagination";
import { Table } from "../Table/Table";
import { getUserManagementHistory } from "../../api/user-management-history";
import { formatUTCDateToTimePartAndDatePart } from "../../utils/utils";
import { isEmpty } from "lodash";
import { HistoryActivateUserAccountModal } from "./HistoryActivateUserAccount";
import { HistoryDeleteUserAccountModal } from "./HistoryDeleteUserAccount";
import { NoRecordFoundModal } from "./NoRecordFoundModal/NoRecordFoundModal";
import { Spinner } from "../Spinner/Spinner";
import { i18n } from "@lingui/core";

const columns: readonly Column<Row>[] = [
  {
    key: "email",
    name: "Admin User Email",
    width: "15%",
  },
  {
    key: "createdAt",
    name: "Time",
    width: "15%",
  },
  {
    key: "actionType",
    name: "Action Type",
    width: "10%",
  },
  {
    key: "actionDetail",
    name: "Action Details",
    sortable: false,
    width: "50%",
    cellClass: "line-height",
  },
  {
    key: "view",
    name: "View",
    sortable: false,
    width: "10%",
  },
];

export interface Row {
  email: string;
  createdAt: string;
  actionType: string;
  actionDetail: React.ReactElement;
  view?: React.ReactElement;
}

interface SortOptions {
  page: number;
  limit: number;
  sortBy: string;
  sortOrder: string;
}

enum ActionType {
  ACTIVE = "Activate",
  DELETE = "Delete",
  SEND_RECOVER_EMAIL = "Send recover email",
  EDIT = "Edit",
}

enum UserManagementActionType {
  ACTIVE = "ACTIVE",
  EDIT = "EDIT",
  DELETE = "DELETE",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  SEND_RECOVER_EMAIL = "SEND_RECOVER_EMAIL",
}

export const History = () => {
  const [rows, setRows] = useState<Row[]>([]);
  const [sortColumns, setSortColumns] = useState<readonly SortColumn[]>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [sortOptions, setSortOptions] = useState<SortOptions>({
    page: 1,
    limit: 10,
    sortBy: "createdAt",
    sortOrder: "desc",
  });
  const [errorMessageCreatedDate, setErrorMessageCreatedDate] =
    useState<string>();
  const [searchCondition, setSearchCondition] = useState({});
  const [detailId, setDetailId] = useState<string>("");
  const [isShowActivateModal, setIsShowActivateModal] =
    useState<boolean>(false);
  const [isShowDeleteModal, setIsShowDeleteModal] = useState<boolean>(false);
  const [isShowNoRecordFoundModal, setIsShowNoRecordFoundModal] =
    useState<boolean>(false);
  const [isShowSpinner, setIsShowSpinner] = useState<boolean>(false);
  const [disableSearchButton, setDisableSearchButton] =
    useState<boolean>(false);

  function showDetails(id: string, actionType: string) {
    if (actionType === ActionType.ACTIVE) {
      setIsShowActivateModal(true);
    } else {
      setIsShowDeleteModal(true);
    }
    setDetailId(id);
  }

  const fetchData = async (sortOptions: SortOptions, searchCondition: any) => {
    setIsShowSpinner(true);
    const response = await getUserManagementHistory({
      ...sortOptions,
      ...searchCondition,
    });

    response.result.map((row: any) => {
      row.createdAt = formatUTCDateToTimePartAndDatePart(row.createdAt);
      row.actionDetail = (
        <td
          dangerouslySetInnerHTML={{
            __html: String(row.actionDetail).replaceAll("\n", "<br />"),
          }}
        />
      );
      if (
        row.actionType === UserManagementActionType.ACTIVE ||
        row.actionType === UserManagementActionType.DELETE
      ) {
        row.view = (
          <span
            style={{ color: "#339af0", textDecoration: "underline" }}
            onClick={() => showDetails(row.id, row.actionType)}
          >
            Details
          </span>
        );
      }
      row.actionType = ActionType[row.actionType as keyof typeof ActionType];
      return row;
    });
    if (response.result.length === 0) {
      setIsShowNoRecordFoundModal(true);
    }
    setRows(response.result);
    setTotalCount(response.countSearch);
    setIsShowSpinner(false);
  };

  const onSortColumns = (sortColumns: SortColumn[], searchCondition: any) => {
    let sortOptionsUpdate = { ...sortOptions };

    if (!isEmpty(sortColumns)) {
      sortOptionsUpdate.sortBy = sortColumns[0].columnKey;
      sortOptionsUpdate.sortOrder = sortColumns[0].direction.toLowerCase() as
        | "asc"
        | "desc";
    }
    setSortOptions(sortOptionsUpdate);

    fetchData(sortOptionsUpdate, searchCondition);
    setSortColumns(sortColumns);
  };

  return (
    <RestrictedTo roles={[Role.ADMINISTRATOR]} redirects={true}>
      <div className={styles.container}>
        <PageHeader title="History" />

        <div className={styles.historyContainer}>
          <Formik
            initialValues={{
              email: "",
              dateFrom: "",
              dateTo: "",
              edit: false,
              active: false,
              delete: false,
              sendRecoverEmail: false,
            }}
            validate={(values) => {
              setErrorMessageCreatedDate(undefined);
              setDisableSearchButton(false);
              if (
                values.dateFrom &&
                values.dateTo &&
                new Date(values.dateFrom).getTime() >
                  new Date(values.dateTo).getTime()
              ) {
                setDisableSearchButton(true);
                setErrorMessageCreatedDate("Invalid date");
              }

              const searchCondition = {
                email: "",
                dateFrom: "",
                dateTo: "",
                actionType: [] as String[],
              };
              if (values.email) {
                searchCondition.email = values.email;
              }
              if (values.dateFrom) {
                searchCondition.dateFrom = new Date(
                  values.dateFrom
                ).toDateString();
              }
              if (values.dateTo) {
                searchCondition.dateTo = new Date(values.dateTo).toDateString();
              }

              // ACTION TYPE
              if (values.delete) {
                searchCondition.actionType.push(
                  UserManagementActionType.DELETE
                );
              } else {
                searchCondition.actionType = searchCondition.actionType.filter(
                  (e) => e !== UserManagementActionType.DELETE
                );
              }
              if (values.edit) {
                searchCondition.actionType.push(UserManagementActionType.EDIT);
              } else {
                searchCondition.actionType = searchCondition.actionType.filter(
                  (e) => e !== UserManagementActionType.EDIT
                );
              }
              if (values.active) {
                searchCondition.actionType.push(
                  UserManagementActionType.ACTIVE
                );
              } else {
                searchCondition.actionType = searchCondition.actionType.filter(
                  (e) => e !== UserManagementActionType.ACTIVE
                );
              }
              if (values.sendRecoverEmail) {
                searchCondition.actionType.push(
                  UserManagementActionType.SEND_RECOVER_EMAIL
                );
              } else {
                searchCondition.actionType = searchCondition.actionType.filter(
                  (e) => e !== UserManagementActionType.SEND_RECOVER_EMAIL
                );
              }
              setSearchCondition(searchCondition);
            }}
            onSubmit={async (values) => {
              await fetchData(sortOptions, searchCondition);
            }}
          >
            {({
              isValid,
              isSubmitting,
              values,
              handleChange,
              touched,
              errors,
              resetForm,
            }) => (
              <Form>
                <Grid>
                  <GridItem width="1/3">
                    <TextInput
                      id="email"
                      label="Admin User Email"
                      value={values.email}
                      onChange={handleChange}
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <DatepickerField
                      label="Date from"
                      placeholderLabel="dd/mm/yyyy"
                      id="dateFrom"
                      name="dateFrom"
                      onChange={handleChange}
                      errorMessage={errorMessageCreatedDate}
                      locale="en"
                    />
                  </GridItem>

                  <GridItem width="1/3">
                    <DatepickerField
                      label="Date To"
                      placeholderLabel="dd/mm/yyyy"
                      id="dateTo"
                      name="dateTo"
                      onChange={handleChange}
                      errorMessage={errorMessageCreatedDate}
                      locale="en"
                    />
                  </GridItem>
                </Grid>

                <Grid>
                  <GridItem width="2/3">
                    <div className={styles.checkBoxGroupContainer}>
                      <p className={styles.checkBoxGroupContainer__label}>
                        Action Types
                      </p>
                      <LabeledCheckbox
                        id="edit"
                        label="Edit"
                        value="edit"
                        mode="small"
                        checked={values.edit}
                        onChange={handleChange}
                      ></LabeledCheckbox>

                      <LabeledCheckbox
                        id="active"
                        label="Activate"
                        value="active"
                        mode="small"
                        checked={values.active}
                        onChange={handleChange}
                      ></LabeledCheckbox>

                      <LabeledCheckbox
                        id="delete"
                        label="Delete"
                        value="delete"
                        mode="small"
                        checked={values.delete}
                        onChange={handleChange}
                      ></LabeledCheckbox>

                      <LabeledCheckbox
                        id="sendRecoverEmail"
                        label="Send Recover Email"
                        value="sendRecoverEmail"
                        mode="small"
                        checked={values.sendRecoverEmail}
                        onChange={handleChange}
                      ></LabeledCheckbox>
                    </div>
                  </GridItem>

                  <GridItem width="1/3">
                    <></>
                  </GridItem>
                </Grid>

                <div className={styles.groupButtonRow}>
                  <Grid>
                    <GridItem width="2/3">
                      <></>
                    </GridItem>
                    <GridItem width="1/3" className={styles.groupButton}>
                      <Button
                        label="clear"
                        btnStyle="secondary"
                        onClick={() => {
                          resetForm();
                          setErrorMessageCreatedDate(undefined);
                          setDisableSearchButton(false);
                        }}
                      ></Button>

                      <Button
                        label="search"
                        className={styles.btnSearch}
                        type="submit"
                        disabled={disableSearchButton}
                      ></Button>
                    </GridItem>
                  </Grid>
                </div>
              </Form>
            )}
          </Formik>

          <div className={styles.tableContainer}>
            <Table
              columns={columns}
              rows={rows}
              onRowsChange={setRows}
              sortColumns={sortColumns}
              rowKeyGetter={undefined}
              selectedRows={undefined}
              onSelectedRowsChange={undefined}
              onSortColumnsChange={(sortColumns) => {
                onSortColumns(sortColumns, searchCondition);
              }}
              rowHeight={100}
            />

            <Pagination
              items={rows}
              totalCount={totalCount}
              getItemsPerPage={async (itemsPerPage: number) => {
                let sortOptionsUpdate = { ...sortOptions };
                sortOptionsUpdate.limit = itemsPerPage;
                sortOptionsUpdate.page = 1;
                await fetchData(sortOptionsUpdate, searchCondition);
              }}
              getPageNumber={async (pageNumber: number) => {
                // page
                let sortOptionsUpdate = { ...sortOptions };
                sortOptionsUpdate.page = pageNumber;
                await fetchData(sortOptionsUpdate, searchCondition);
              }}
            />
          </div>
        </div>
      </div>
      {isShowActivateModal && (
        <HistoryActivateUserAccountModal
          isShowing={isShowActivateModal}
          setVisible={function (isShowing: boolean): void {
            setIsShowActivateModal(isShowing);
          }}
          id={detailId}
        />
      )}
      {isShowDeleteModal && (
        <HistoryDeleteUserAccountModal
          isShowing={isShowDeleteModal}
          setVisible={function (isShowing: boolean): void {
            setIsShowDeleteModal(isShowing);
          }}
          id={detailId}
        />
      )}
      {isShowNoRecordFoundModal && (
        <NoRecordFoundModal
          isShowing={isShowNoRecordFoundModal}
          setVisible={setIsShowNoRecordFoundModal}
        />
      )}
      <Spinner
        type="fullContainer"
        title={i18n._({ id: "loading" })}
        visible={isShowSpinner}
      />
    </RestrictedTo>
  );
};
