import React from "react";
import { ReactComponent as ClassPadLabel } from "../../assets/svg/classpad-label.svg";
import styles from "./Footer.module.scss";
interface FooterProps {
  isFrench: boolean;
}

export const Footer: React.FC<FooterProps> = ({ isFrench }) => {
  const linkData = isFrench
    ? [
        {
          name: "POLITIQUE DE CONFIDENTIALITÉ",
          to: "/fr/politique-de-protection-des-donnees-classpad-academy",
        },
        {
          name: "DOCUMENTATION JURIDIQUE POUR LE GAR",
          to: "/fr/documentation-pour-le-gar",
        },
        {
          name: "CONDITIONS DE SERVICE",
          to: "/fr/conditions-de-service",
        },
      ]
    : [
        {
          name: "IMPRESSUM",
          to: "/impressum",
        },
        {
          name: "DATENSCHUTZ",
          to: "/datenschutz",
        },
        {
          name: "NUTZUNGSBEDINGUNGEN",
          to: "/nutzungsbedingungen",
        },
      ];

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__contact}>
        <ClassPadLabel className={styles.footer__contact__logo} />
        {!isFrench && (
          <div className={styles.footer__contact__email}>
            <p className={styles.footer__contact__email__text}>
              {`Email: `}
              <a
                href="mailto:classpad.academy@casio.de"
                target="_blank"
                rel="noreferrer"
                className={styles.footer__contact__email__anchor}
              >
                classpad.academy@casio.de
              </a>
            </p>
          </div>
        )}
      </div>
      {/* onClick={() => { navigate(item.to, {replace: true}) }} */}
      <nav className={styles.footer__navigation}>
        <ul>
          {linkData.map((item, index) => {
            return (
              <li key={index}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a href={item.to} target="_blank" rel="noreferrer">
                  {item.name}
                </a>
              </li>
            );
          })}
        </ul>
      </nav>
      <div className={styles.footer__copyright}>
        &copy; {new Date().getFullYear()} Casio Computer Co., LTD.
      </div>
    </footer>
  );
};
