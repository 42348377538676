import { RouteProps } from "react-router-dom";
import { useAuth } from "../../hooks/use-auth";
import { Spinner } from "../Spinner/Spinner";
import { i18n } from "@lingui/core";

const PrivateRoute = ({ children, element, ...rest }: RouteProps) => {
  const auth = useAuth();

  const render = () => {
    if (auth.loading) {
      return <Spinner type="fullContainer" title={i18n._({ id: "loading" })} />;
    }

    if (!auth.loggedIn) {
      window.location.replace("/app/login");
      return <Spinner type="fullContainer" title={i18n._({ id: "loading" })} />;
    }

    if (children) {
      return <>{children}</>;
    }

    if (!element) {
      return null;
    }
    return <>{element}</>;
  };
  return render();
};

export default PrivateRoute;
