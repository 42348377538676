import { i18n } from "@lingui/core";
import { StepBody } from "../Stepper/Stepper";
import { Button } from "../Button/Button";
import { SelectionBox } from "../SelectionBox/SelectionBox";
import { PaymentType } from "../../api/purchases";
import styles from "./LicensePurchaseModal.module.scss";
import { PaymentSelectionEntry } from "./PaymentSelection";
import { CollapsableContentPaypal, initUnzerPaypal } from "./Paypal";
import { CreditCard, initUnzerCreditCard } from "./CreditCard";
import { useEffect, useState } from "react";
import {
  Unzer,
  UnzerPaymentMethod,
  UnzerResource,
  createJsession,
} from "../../types/unzer";
import { Spinner } from "../Spinner/Spinner";
import { useToasts } from "../../provider/toast-provider";

export declare const unzer: new (
  publicKey: string,
  options: { locale: string }
) => Unzer;

export interface LicensePurchaseModalStep3Props {
  onSubmit: (unzerResource: UnzerResource) => void;
  onGoBack: () => void;
  loading: boolean;
}

export const LicensePurchaseModalStep3 = ({
  onSubmit,
  onGoBack,
  loading,
}: LicensePurchaseModalStep3Props) => {
  const { addToast } = useToasts();

  const [unzerCard, setUnzerCard] = useState<UnzerPaymentMethod>();
  const [unzerPaypal, setUnzerPaypal] = useState<UnzerPaymentMethod>();
  const [validating, setValidating] = useState<boolean>(false);
  const [sessionUsed, setSessionUsed] = useState<boolean>(false);

  const [selectedPaymentType, setSelectedPaymentType] =
    useState<PaymentType | null>();
  const [unzerResource, setUnzerResource] = useState<UnzerResource>();

  const [fieldsValid, setFieldsValid] = useState<{
    number: boolean;
    expiry: boolean;
    cvc: boolean;
  }>({ number: false, expiry: false, cvc: false });

  useEffect(() => {
    const unzerInstance = new unzer(window.unzerPublicKey, { locale: "de-DE" });

    const paypal = initUnzerPaypal(unzerInstance);
    paypal.addEventListener("change", () => setUnzerResource(undefined));
    setUnzerPaypal(paypal);

    const creditCard = initUnzerCreditCard(unzerInstance);
    const eventHandlerCardInput = function (event: {
      type?: "number" | "expiry" | "cvc";
      success?: boolean;
    }) {
      if (event.type) {
        const update: {
          number?: boolean;
          expiry?: boolean;
          cvc?: boolean;
        } = {};
        update[event.type] = !!event.success;
        setFieldsValid((fieldsValid) => ({
          ...fieldsValid,
          ...update,
        }));
      }
      // when credit card input changes, invalidate the the resource
      // this also happens on field focus
      setUnzerResource(undefined);
    };
    creditCard.addEventListener("change", eventHandlerCardInput);
    setUnzerCard(creditCard);
  }, []);

  /*
   * To handle credit cards securely, unzer uses special methods to create resources.
   * When creating the Card object, they fetch a jsessionId which is used to validate requests.
   * Unfortunately only one credit card resource can be created for each session id.
   * To circumvent this limitation, we fetch a new session id whenever a user changes credit card data.
   * This enabled us to validate credit card infomration more than once.
   */
  const renewSessionId = async () => {
    try {
      const jsessionId = await createJsession();
      setUnzerCard((unzerCard) => {
        if (unzerCard) {
          const update = unzerCard;
          update.config.jsessionId = jsessionId;
          return update;
        }
      });
    } catch (err) {
      addToast(
        i18n._({
          id: "license.purchase.payment-method.creditcard.error",
        }),
        "error"
      );
    }
  };

  const handleSubmit = async () => {
    if (selectedPaymentType && unzerCard && unzerPaypal && !validating) {
      try {
        setValidating(true);
        // if we already created a resource and the user did not change
        // any payment information, we just reuse the old resource.
        if (
          unzerResource &&
          selectedPaymentType.toLowerCase() === unzerResource.method
        ) {
          onSubmit(unzerResource);
          return;
        }

        // renew the unzer session before trying to sudmit a new credit card
        if (sessionUsed && selectedPaymentType === PaymentType.CARD) {
          await renewSessionId();
          setSessionUsed(false);
        }

        const method =
          selectedPaymentType === PaymentType.CARD ? unzerCard : unzerPaypal;
        const resource = await method.createResource();
        setUnzerResource(resource);
        if (selectedPaymentType === PaymentType.CARD) {
          setSessionUsed(true);
        }
        onSubmit(resource);
      } catch (err: any) {
        addToast(err.message, "error");
      } finally {
        setValidating(false);
      }
    }
  };

  return (
    <StepBody
      previousButton={
        <Button
          label={i18n._({
            id: "license.purchase.button.previous",
          })}
          btnStyle={"secondary"}
          onClick={() => onGoBack()}
        />
      }
      nextButton={
        <Button
          label={i18n._({ id: "license.purchase.button.next" })}
          type="submit"
          disabled={
            !selectedPaymentType ||
            (selectedPaymentType === PaymentType.CARD &&
              (!fieldsValid.number ||
                !fieldsValid.expiry ||
                !fieldsValid.cvc)) ||
            loading
          }
          onClick={handleSubmit}
        />
      }
    >
      <Spinner
        type="fullContainer"
        title={i18n._({ id: "loading" })}
        visible={validating}
      />
      <div className={styles.purchaseMethod}>
        <SelectionBox
          title={i18n._({
            id: "license.purchase.payment-method.label",
          })}
        >
          <PaymentSelectionEntry
            name="payment"
            label={i18n._({
              id: "license.purchase.payment-method.paypal",
            })}
            value={PaymentType.PAYPAL}
            isCollapsed={selectedPaymentType !== PaymentType.PAYPAL}
            collapsableContent={<CollapsableContentPaypal />}
            onChange={() => setSelectedPaymentType(PaymentType.PAYPAL)}
          />
          <PaymentSelectionEntry
            name="payment"
            label={i18n._({
              id: "license.purchase.payment-method.card",
            })}
            value={PaymentType.CARD}
            isCollapsed={selectedPaymentType !== PaymentType.CARD}
            collapsableContent={<CreditCard />}
            onChange={() => setSelectedPaymentType(PaymentType.CARD)}
          />
        </SelectionBox>
      </div>
      <span>
        {i18n._({
          id: "license.purchase.payment-method.hint",
        })}
      </span>
    </StepBody>
  );
};
