import { Button } from "../Button/Button";
import { Modal, ModalProps } from "../Modal/Modal";
import styles from "./AdminBroadcastModal.module.scss";
import { i18n } from "@lingui/core";
import { useToasts } from "../../provider/toast-provider";
import { TextArea } from "../TextArea/TextArea";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { sendBroadcast } from "../../api/message";
import BroadcastGroupBox, { BroadcastObject } from "./BroadcastGroupBox";
import { BroadcastGroup } from "../../types/BroadcastGroup";
import { Spinner } from "../Spinner/Spinner";

export interface SupportNewMessageModalProps extends ModalProps {
  mode: "message" | "broadcast";
  newMessageModalVisible: boolean;
}

export const AdminBroadcastModal = ({
  mode,
  newMessageModalVisible,
  ...modalProps
}: SupportNewMessageModalProps) => {
  const { addToast } = useToasts();

  type SendMessageFormData = {
    message_text: string;
  };

  const sendMessageForm = useForm<SendMessageFormData>({ mode: "onChange" });

  const [loading, setLoading] = useState<boolean>(false);

  const [broadcastObject, setBroadcastObject] = useState<BroadcastObject>();
  const [country, setCountry] = useState<string>();
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  function resetAllInputs() {
    sendMessageForm.reset();
    setIsSubmitted(false);
    setIsDisabled(false);
  }

  const getDataFromBroadcast: any = (data: BroadcastObject) => {
    setBroadcastObject(data);
    if (country !== data.country) {
      sendMessageForm.reset();
    }
    setCountry(data.country);
  };

  useEffect(() => {
    sendMessageForm.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMessageModalVisible]);

  async function submitSendMessageForm(data: SendMessageFormData) {
    setLoading(true);
    setIsSubmitted(true);
    try {
      if (mode === "broadcast" && broadcastObject) {
        const broadcast = await sendBroadcast({
          text: data.message_text,
          to: BroadcastGroup.ALL_USERS,
          data: broadcastObject,
        });
        addToast(
          i18n._({
            id: "support.messages.new.modal.send.success",
            values: { amount: broadcast.receiverCount },
          })
        );
      }
      resetAllInputs();
    } catch (err) {
      addToast(
        i18n._({ id: "support.messages.new.modal.send.error" }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal {...modalProps} size="large">
        <Spinner
          type="fullContainer"
          title={i18n._({ id: "loading" })}
          visible={loading}
        />
        <div className={styles.supNewMsgModal__container}>
          <BroadcastGroupBox
            getDataFromBroadcast={(data: any) => {
              getDataFromBroadcast(data);
            }}
            isSubmitted={isSubmitted}
            getIsDisable={function (data: boolean) {
              setIsDisabled(data);
            }}
          />
          <div className={styles.supNewMsgModal__msg_box}>
            <form data-testid="sendMessageForm">
              <TextArea
                label={"Message"}
                id="message_text"
                className={styles.supNewMsgModal__msg_box__input}
                {...sendMessageForm.register("message_text", {
                  minLength: 10,
                  required: true,
                })}
                errorMessage={
                  sendMessageForm.formState.errors.message_text &&
                  i18n._({
                    id: "forms.validation.length.min",
                    values: { min: 10 },
                  })
                }
              ></TextArea>
              <div className={styles.supNewMsgModal__msg_box__button}>
                <Button
                  label={i18n._({
                    id: "SEND MESSAGE",
                  })}
                  type="submit"
                  onClick={sendMessageForm.handleSubmit(submitSendMessageForm)}
                  disabled={
                    loading || !sendMessageForm.formState.isValid || isDisabled
                  }
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
