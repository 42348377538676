import { useEffect, useState } from "react";
import { i18n } from "@lingui/core";
import { deleteUser, getUser, updateUser } from "../../api/user";
import { useToasts } from "../../provider/toast-provider";
import { Context } from "../../types/Context";
import { User } from "../../types/User";
import { UserProfileView } from "./UserProfileView";
import { getSolelyManagedContexts } from "../../api/user-contexts";
import { Operation } from "fast-json-patch";
import { useAuth } from "../../hooks/use-auth";
import { Spinner } from "../Spinner/Spinner";
import { SchoolOption } from "../SchoolSelect/SchoolSelect";
import { Role } from "../../types/Role";
import { addSchool } from "../../api/school";
import { AddSchoolFormSchema } from "../AddSchool/AddSchool";
import { IdProvider } from "../../types/IdProvider";
import { detectLocale } from "../IntlHandler/IntlHelper";
import { Country, Language } from "../../types/Country";
import { SchoolType } from "../AddSchool/SchoolTypes";

export interface UpdateUserSchema {
  salutation: string;
  nickname: string;
  firstname?: string;
  lastname?: string;
  internalUser: boolean;
  type: Role;
  email?: string;
}

export interface UpdateUserSchoolSchema {
  subjects: string[];
  studentCount: number;
  teachingLevel?: SchoolType[];
  school?: SchoolOption;
  addManualSchool: boolean;
  addSchool?: AddSchoolFormSchema;
}
export interface UpdateTisConsentSchema {
  tisMarketingConsent: boolean;
  tisProfilingConsent: boolean;
}
export interface UpdateTisConsentLMSSchema {
  tisConsent: UpdateTisConsentSchema;
  school: UpdateUserSchoolSchema;
  user: UpdateUserSchema;
}

interface UserProfileProps {
  nicknameChanged: boolean;
}

export const UserProfile = ({ nicknameChanged }: UserProfileProps) => {
  const auth = useAuth();
  const { addToast } = useToasts();
  const [user, setUser] = useState<User>();
  const [solelyManagedContexts, setSolelyManagedContexts] = useState<Context[]>(
    []
  );
  const [loading, setLoading] = useState<boolean>(false);
  const defaultLocale = detectLocale();
  const isFrenchUser = defaultLocale === Language.fr;

  const userId = auth?.userInfo?.userId || "";

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUser({ userId });
      setUser(user);
    };

    fetchUser();
  }, [userId, nicknameChanged]);
  const internalUser = user?.idProvider === IdProvider.CLASSPAD;

  async function handleSubmit(values: UpdateUserSchema) {
    try {
      const patch = createUpdateUserPatch(values);
      const user = await updateUser({ userId, patch });
      setUser(user);
      addToast(i18n._({ id: "user.profile.save.success" }));
    } catch (err) {
      addToast(i18n._({ id: "user.profile.save.error" }), "error");
    }
  }

  const createUpdateUserPatch = (values: UpdateUserSchema) => {
    const patch: Operation[] = [
      {
        op: "replace",
        path: "/firstname",
        value: values.firstname || undefined,
      },
      {
        op: "replace",
        path: "/lastname",
        value: values.lastname || undefined,
      },
      { op: "replace", path: "/nickname", value: values.nickname },
    ];

    if (values.type === Role.INSTRUCTOR) {
      patch.push({
        op: "replace",
        path: "/metadata/salutation",
        value: values.salutation || undefined,
      });
    }
    if (!internalUser) {
      patch.push({
        op: "replace",
        path: "/email",
        value: values.email,
      });
    }
    return patch;
  };
  async function handleTISSubmit(values: UpdateTisConsentSchema) {
    try {
      const patch = createTisConsentPatch(values);
      const newUser = await updateUser({ userId, patch });
      setUser(newUser);
      addToast(i18n._({ id: "user.profile.save.salesforce.success" }));
    } catch (err) {
      addToast(i18n._({ id: "user.profile.save.salesforce.error" }), "error");
    }
  }

  const createTisConsentPatch = (values: UpdateTisConsentSchema) => {
    const patch: Operation[] = [
      {
        op: "replace",
        path: "/metadata/tisMarketingConsent",
        value: values.tisMarketingConsent,
      },
      {
        op: "replace",
        path: "/metadata/tisProfilingConsent",
        value: isFrenchUser
          ? values.tisMarketingConsent
          : values.tisProfilingConsent,
      },
    ];
    return patch;
  };

  async function handleSchoolDataSubmit(values: UpdateUserSchoolSchema) {
    try {
      let schoolId = "";
      if (values.addManualSchool && values.addSchool) {
        values.addSchool.schoolcountry = isFrenchUser ? Country.FR : Country.DE;
        schoolId = await addSchool(values.addSchool);
      }
      const patch = createSchoolUpdatePatch(values, schoolId);
      const user = await updateUser({ userId, patch });
      setUser(user);
      addToast(i18n._({ id: "user.profile.save.school.success" }));
    } catch (err) {
      addToast(i18n._({ id: "user.profile.save.school.error" }), "error");
    }
  }

  const createSchoolUpdatePatch = (
    values: UpdateUserSchoolSchema,
    schoolId: string
  ) => {
    const patch: Operation[] = [
      {
        op: "replace",
        path: "/schoolId",
        value: values.addManualSchool ? schoolId : values.school?.value,
      },
      { op: "replace", path: "/metadata/subjects", value: values.subjects },
      {
        op: "replace",
        path: "/metadata/school/studentCount",
        value: values.studentCount,
      },
      {
        op: "replace",
        path: "/metadata/teachingLevel",
        value: values.teachingLevel?.length
          ? values.teachingLevel.map((type) => type.value)
          : undefined,
      },
    ];
    return patch;
  };

  async function handleDeletionAttempt(): Promise<void> {
    const contexts = await getSolelyManagedContexts({
      userId,
    });
    setSolelyManagedContexts(contexts);
  }

  async function handleDeletionConfirm(keepTIS: boolean) {
    try {
      // mark consenting user for keeping TIS
      if (keepTIS && user?.tisMarketingConsent) {
        const patch: Operation[] = [
          {
            op: "replace",
            path: "/metadata/keepTIS",
            value: true,
          },
        ];
        await updateUser({ userId, patch });
      }
      await deleteUser();
      window.location.replace("/app/meta/logout");
    } catch (err) {
      addToast(i18n._({ id: "user.profile.delete.error" }), "error");
    }
  }

  async function handleLMSDataChange(
    data: UpdateTisConsentLMSSchema
  ): Promise<void> {
    setLoading(true);
    try {
      const userpatch = createUpdateUserPatch(data.user);
      let schoolId = "";
      if (data.school.addManualSchool && data.school.addSchool) {
        data.school.addSchool.schoolcountry = isFrenchUser
          ? Country.FR
          : Country.DE;
        schoolId = await addSchool(data.school.addSchool);
      }
      const schoolpatch = createSchoolUpdatePatch(data.school, schoolId);
      const tisConsentPatch = createTisConsentPatch(data.tisConsent);
      const newUser = await updateUser({
        userId,
        patch: userpatch.concat(schoolpatch, tisConsentPatch),
      });
      setUser(newUser);

      addToast(i18n._({ id: "user.profile.save.salesforce.success" }));
    } catch (err) {
      addToast(i18n._({ id: "user.profile.save.salesforce.error" }), "error");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Spinner
        type="fullContainer"
        title={i18n._({ id: "loading" })}
        visible={user ? false : true}
      />
      {user && (
        <UserProfileView
          user={user}
          solelyManagedContexts={solelyManagedContexts}
          handleSubmit={handleSubmit}
          handleSchoolDataSubmit={handleSchoolDataSubmit}
          handleDeletionAttempt={handleDeletionAttempt}
          handleDeletionConfirm={handleDeletionConfirm}
          handleTISSubmit={handleTISSubmit}
          handleLMSDataChange={handleLMSDataChange}
          loading={loading}
        />
      )}
    </>
  );
};
