import styles from "./LMSNicknameModal.module.scss";
import { Trans } from "@lingui/react";
import { i18n } from "@lingui/core";
import { BlockContent } from "../BlockContent/BlockContent";

interface TermsAndConditionsProps {}

export const TermsAndConditions: React.FC<TermsAndConditionsProps> = () => {
  return (
    <div className={styles.lmsNicknameModal__termsAndConditions}>
      <BlockContent className={styles.lmsNicknameModal__privacyPolicy}>
        <Trans
          id="register.form.privacy_policy"
          values={{
            data_protection_link: (
              <a target="_blank" href="/datenschutz">
                {i18n._({
                  id: "register.form.terms_and_conditions.data_protection",
                })}
              </a>
            ),
          }}
        />
      </BlockContent>
      <BlockContent className={styles.lmsNicknameModal__privacyPolicy}>
        <Trans
          id="lms.nickname.modal.inform"
          values={{
            conditions_link: (
              <a target="_blank" href="/nutzungsbedingungen">
                {i18n._({
                  id: "lms.nickname.modal.inform.conditions_link",
                })}
              </a>
            ),
          }}
        />
      </BlockContent>
    </div>
  );
};
