import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import styles from "./Modal.module.scss";
import { X } from "react-feather";
import { classList } from "../../lib/helpers";
import { Spinner } from "../Spinner/Spinner";

export interface ModalProps {
  isShowing?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  size?: "default" | "large" | "medium" | "superlarge";
  paddedContent?: boolean;
  title?: string;
  closeButton?: boolean;
  icon?: React.ReactNode;
  className?: string;
  onHide?: () => void;
  onClose?: () => void;
}

export const transitionClasses = {
  appear: styles.transition_appear,
  appearActive: "",
  appearDone: styles.transition_appearDone,
  enter: styles.transition_enter,
  enterActive: "",
  enterDone: styles.transition_enterDone,
  exit: styles.transition_exit,
  exitActive: styles.transition_exitActive,
  exitDone: "",
};

export const transitionTimeout = 300;

export const Modal = ({
  isShowing,
  isLoading = false,
  onHide,
  children,
  title,
  size = "default",
  paddedContent = true,
  closeButton = true,
  icon,
  className,
  onClose,
}: ModalProps) => {
  const containerRef = React.useRef(null);
  const modalClasses = classList(
    styles.modal,
    isLoading && styles.modal_loading,
    className
  );
  const dialogClasses = classList(
    styles.modal__dialog,
    styles["modal__dialog_" + size],
    paddedContent && styles.modal__dialog__padded
  );

  return ReactDOM.createPortal(
    <CSSTransition
      nodeRef={containerRef}
      in={isShowing}
      timeout={transitionTimeout}
      classNames={transitionClasses}
      unmountOnExit
      appear
    >
      <div ref={containerRef} className={modalClasses}>
        <div className={styles.modal__backdrop} onClick={onHide} />
        <Spinner
          type="fullContainer"
          visible={isShowing && isLoading}
          appearance="white"
        />
        <div
          className={dialogClasses}
          aria-modal
          aria-hidden={!isShowing || isLoading}
          tabIndex={-1}
          role="dialog"
        >
          <div
            className={classList(
              styles.modal__dialog__header,
              !paddedContent && styles.modal__dialog__padded
            )}
          >
            <h2 className={styles.modal__dialog__header__title}>{title}</h2>
            {icon && <i>{icon}</i>}
            {closeButton && (
              <button
                type="button"
                className={styles.modal__dialog__header__close}
                data-dismiss="modal"
                aria-label="Close"
                onClick={onHide || onClose}
              >
                <X />
              </button>
            )}
          </div>
          <div className={styles.modal__dialog__body}>{children}</div>
        </div>
      </div>
    </CSSTransition>,
    document.body
  );
};
