import React from "react";
import ReactDOM from "react-dom";
import App from "./components/App/App";
import reportWebVitals from "./reportWebVitals";
import "./assets/_global.scss";
import { TrackingProvider } from "./hooks/use-tracking";
import IntlHandler from "./components/IntlHandler/IntlHandler";
import { AuthProvider } from "./hooks/use-auth";
import { AppConfigProvider } from "./hooks/use-app-config";

declare global {
  interface Window {
    featureToggles: Record<string, boolean>;
    unzerPublicKey: string;
    matheMarathonDomain: string;
    classpadNetUrl: string;
    cplTestPeriodLearnerDuration: number;
    teacherEmailBlocklist: string[];
    cpLearningFreeTrialPeriodStart: string;
    cpLearningFreeTrialPeriodEnd: string;
    classpadLearningUrl: string;
    mesExcercisesLyceeLaunchUrl: string;
  }
}

async function bootstrapApplication() {
  ReactDOM.render(
    <React.StrictMode>
      <TrackingProvider domain="classpad.academy">
        <AppConfigProvider>
          <AuthProvider>
            <IntlHandler>
              <App />
            </IntlHandler>
          </AuthProvider>
        </AppConfigProvider>
      </TrackingProvider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

bootstrapApplication();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
