export enum AdditionalLicenseType {
  CLASSPAD_PLUS_MANAGER = "CLASSPAD_PLUS_MANAGER",
  CLASSPAD_PLUS_APP = "CLASSPAD_PLUS_APP",
}

export interface ClasspadNetPlusAppLicense {
  email: string;
  password: string;
}

export interface ClasspadNetPlusManagerLicense {
  code: string;
}
