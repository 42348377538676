import { Modal } from "../../Modal/Modal";
import { Grid, GridItem } from "../../Grid/Grid";
import styles from "./VerifyLegalGuardianModal.module.scss";
import { Button } from "../../Button/Button";

export const VerifyLegalGuardianModal = ({
  isShowing,
  onConfirm,
  onCancel,
}: {
  isShowing: boolean;
  onConfirm: () => void;
  onCancel: () => void;
}) => {
  return (
    <>
      <Modal
        title="Confirm Verify Legal Guardian"
        isShowing={isShowing}
        closeButton={false}
        size="medium"
      >
        <div className={styles.container}>
          <p className={styles.confirmModalText}>
            Are you sure you would like to verify legal guardian for the user
            account?
          </p>

          <div className={styles.confirmModalBtnGroup}>
            <Grid>
              <GridItem width="1/2" className={styles.groupBtn1}>
                <Button
                  label="confirm"
                  btnStyle="primary"
                  onClick={onConfirm}
                ></Button>
              </GridItem>

              <GridItem width="1/2" className={styles.groupBtn2}>
                <Button
                  btnStyle="secondary"
                  label="cancel"
                  onClick={onCancel}
                ></Button>
              </GridItem>
            </Grid>
          </div>
        </div>
      </Modal>
    </>
  );
};
