import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Spinner } from "../../Spinner/Spinner";
import styles from "./VerificationFeedbackPageTISOnly.module.scss";
import { i18n } from "@lingui/core";
import { verificateTISOnlyUser } from "../../../api/tis-only";
import { Button } from "../../Button/Button";

export const VerificationFeedbackPageTISOnly = () => {
  const { userId = "", code = "" } = useParams<{
    userId: string;
    code: string;
  }>();
  const [isLoading, setIsLoading] = useState(true);
  const [content, setContent] = useState({ headline: "", text: "" });
  const [status, setStatus] = useState<String>();

  useEffect(() => {
    const verificateUser = async () => {
      const verificationStatus = await verificateTISOnlyUser({ userId, code });
      setStatus(verificationStatus);
      setContent({
        headline: i18n._({
          id: `tisOnly.verification.headline.${verificationStatus}`,
        }),
        text: i18n._({ id: `tisOnly.verification.text.${verificationStatus}` }),
      });
      setIsLoading(false);
    };
    verificateUser();
  }, [userId, code]);

  return (
    <>
      <Spinner
        type="fullContainer"
        title={i18n._({ id: "loading" })}
        visible={isLoading}
      />
      <div className={styles.verificationFeedbackPage}>
        <div className={styles.verificationFeedbackPage__container}>
          <div className={styles.verificationFeedbackPage__container__content}>
            <h1>{content.headline}</h1>
            <p>{content.text}</p>
          </div>
          <div className={styles.verificationFeedbackPage__container__links}>
            {(status === "verificationCodeInvalid" ||
              status === "linkInvalid") && (
              <a href="/app/tis/verification/link">
                <Button
                  label={"Verifizierungslink anfordern"}
                  className={
                    styles.verificationFeedbackPage__container__links__button
                  }
                ></Button>
              </a>
            )}
            {status === "linkInvalid" && (
              <a href="/app/tis/registration">
                <Button
                  label={"Jetzt registrieren"}
                  className={
                    styles.verificationFeedbackPage__container__links__button
                  }
                ></Button>
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
