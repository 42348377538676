import { Button } from "../Button/Button";
import { Modal, ModalProps } from "../Modal/Modal";
import { TextInput } from "../TextInput/TextInput";
import styles from "./LMSNicknameModal.module.scss";
import { i18n } from "@lingui/core";
import { useForm } from "react-hook-form";
import { useAuth } from "../../hooks/use-auth";
import { updateUser } from "../../api/user";
import { Operation } from "fast-json-patch";
import { useToasts } from "../../provider/toast-provider";
import { IdProvider } from "../../types/IdProvider";
import { LMSBadge } from "../LMSBadge/LMSBadge";
import { TermsAndConditions } from "./TermsAndConditions";

export interface LMSNicknameModalViewProps extends ModalProps {
  welcomeText: string;
  description: string;
  idProvider: IdProvider;
  onSubmit: (data: NicknameData) => void;
}

export interface NicknameData {
  nickname: string;
}

export interface LMSNicknameModalProps {
  idProvider: IdProvider;
  nicknameChanged: boolean;
  setNicknameChanged: (nicknameChanged: boolean) => void;
}

export const LMSNicknameModal = ({
  idProvider,
  nicknameChanged,
  setNicknameChanged,
}: LMSNicknameModalProps) => {
  const auth = useAuth();
  const userId = auth.userInfo?.userId || "";

  const { addToast } = useToasts();

  async function onSubmit(data: NicknameData) {
    try {
      const patch: Operation[] = [];
      patch.push({ op: "replace", path: "/nickname", value: data.nickname });
      await updateUser({ userId, patch });
    } catch (err) {
      addToast(i18n._({ id: "user.profile.save.error" }), "error");
    } finally {
      setNicknameChanged(true);
    }
  }

  return (
    <LMSNicknameModalView
      welcomeText={i18n._({ id: "lms.nickname.modal.welcome" })}
      description={i18n._({ id: "lms.nickname.modal.description" })}
      isShowing={!nicknameChanged}
      onSubmit={onSubmit}
      icon={<LMSBadge idProvider={idProvider} />}
      closeButton={false}
      title={i18n._({ id: "lms.nickname.modal.title" })}
      idProvider={idProvider}
    />
  );
};

export const LMSNicknameModalView = ({
  welcomeText,
  description,
  idProvider,
  onSubmit,
  ...modalProps
}: LMSNicknameModalViewProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<NicknameData>({ mode: "onChange" });
  return (
    <form data-testid="nicknameForm">
      <Modal {...modalProps} className={styles.lmsNicknameModal}>
        <div className={styles.lmsNicknameModal__text}>
          <p>{welcomeText}</p>
          <p>{description}</p>
        </div>

        <TextInput
          id="lms_nickname_modal"
          label={i18n._({ id: "lms.nickname.modal.input.label" })}
          placeholder={i18n._({ id: "lms.nickname.modal.input.placeholder" })}
          {...register("nickname", {
            minLength: 2,
            maxLength: 35,
            required: true,
          })}
          errorMessage={
            errors.nickname &&
            i18n._({
              id: "forms.validation.length.between",
              values: { min: 2, max: 35 },
            })
          }
        />
        <TermsAndConditions />
        <Button
          label={i18n._({ id: "lms.nickname.modal.button.submit" })}
          type="submit"
          disabled={!isValid}
          onClick={handleSubmit(onSubmit)}
        />
      </Modal>
    </form>
  );
};
