import { Button } from "../Button/Button";
import { Modal, ModalProps } from "../Modal/Modal";
import styles from "./SupportNewMessageModal.module.scss";
import { i18n } from "@lingui/core";
import { useToasts } from "../../provider/toast-provider";
import { TextArea } from "../TextArea/TextArea";
import { useForm } from "react-hook-form";
import { UserExisting } from "../../api/user";
import { useRef, useState } from "react";
import { sendMessage } from "../../api/message";
import { RecipientBox, RecipientBoxHandle } from "./RecipientBox";
import { Spinner } from "../Spinner/Spinner";

export interface SupportNewMessageModalProps extends ModalProps {
  mode: "message" | "broadcast";
}

export const SupportNewMessageModal = ({
  mode,
  ...modalProps
}: SupportNewMessageModalProps) => {
  const { addToast } = useToasts();

  type SendMessageFormData = {
    message_text: string;
  };

  const sendMessageForm = useForm<SendMessageFormData>({ mode: "onChange" });
  const recipientBoxRef = useRef<RecipientBoxHandle>(null);

  const [recipients, setRecipients] = useState<UserExisting[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  function resetAllInputs() {
    recipientBoxRef.current?.reset();
    setRecipients([]);
    sendMessageForm.reset();
  }

  async function submitSendMessageForm(data: SendMessageFormData) {
    setLoading(true);
    const recipientIds = recipients.map((recipient) => recipient.id);
    try {
      if (mode === "message") {
        const message = await sendMessage({
          text: data.message_text,
          receiverIds: recipientIds,
        });
        addToast(
          i18n._({
            id: "support.messages.new.modal.send.success",
            values: { amount: message.receivers.length },
          })
        );
      }
      resetAllInputs();
    } catch (err) {
      addToast(
        i18n._({ id: "support.messages.new.modal.send.error" }),
        "error"
      );
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Modal {...modalProps} size="large">
        <Spinner
          type="fullContainer"
          title={i18n._({ id: "loading" })}
          visible={loading}
        />
        <div className={styles.supNewMsgModal__container}>
          {mode === "message" && (
            <RecipientBox
              updateRecipients={setRecipients}
              ref={recipientBoxRef}
            />
          )}
          <div className={styles.supNewMsgModal__msg_box}>
            <form data-testid="sendMessageForm">
              <TextArea
                label={i18n._({ id: "support.messages.message" })}
                id="message_text"
                className={styles.supNewMsgModal__msg_box__input}
                {...sendMessageForm.register("message_text", {
                  minLength: 10,
                  required: true,
                })}
                errorMessage={
                  sendMessageForm.formState.errors.message_text &&
                  i18n._({
                    id: "forms.validation.length.min",
                    values: { min: 10 },
                  })
                }
              ></TextArea>
              <div className={styles.supNewMsgModal__msg_box__button}>
                <Button
                  label={i18n._({
                    id: "support.messages.new.modal.send.button",
                  })}
                  type="submit"
                  onClick={sendMessageForm.handleSubmit(submitSendMessageForm)}
                  disabled={loading || !sendMessageForm.formState.isValid}
                />
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};
